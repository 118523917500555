import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Sensus() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Effect XT2';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ cor4, setCor4 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "15", label: "15" },
        {value: "17", label: "17" },
        {value: "19", label: "19" },
        {value: "21", label: "21" },
        {value: "23", label: "23" },
    ];
    
    function padrao1() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#FBE111');
        setCor2('#FBE111');
        setCor3('#FBE111');
        setCor4('#FBE111');
    }

    function padrao2() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#094A39');
        setCor2('#094A39');
        setCor3('#094A39');
        setCor4('#094A39');
    }

    function padrao3() {
        setCort('#F75439');
        setCorb('#F75439');
        setCor1('#1F4194');
        setCor2('#1F4194');
        setCor3('#1F4194');
        setCor4('#1F4194');
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Design 04: '+Cores[cor4].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Design 04: '+Cores[cor4].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="291"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effectxt2_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effectxt2_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effectxt2_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <div className="svg-container2">

                        <svg viewBox="0 0 774.98 418.76" ref={svg_top}>

                            <g stroke="#A6A6A6" strokeWidth="0.5" fill={cort}>
                                <path d="M772.32 142.93c-1.1,2.24 -3.01,3.99 -5.3,4.97 -38.99,16.79 -434.83,186.78 -644.72,258.31 0,0 -70.66,27.85 -100.35,-1.15 0,0 -9.14,-9.89 -19.96,-42.49 -2.27,-6.85 -1.85,-14.32 1.24,-20.87 19.1,-40.56 103.23,-192.35 362.65,-300.4 8.29,-3.46 94.93,-34.82 168.04,-39.54 93.07,-6 130.83,8.86 159.33,34.67 25.74,23.3 71.82,62.72 80.17,90.06 1.64,5.37 1.43,11.2 -1.02,16.27 -0.02,0.06 -0.05,0.12 -0.08,0.17z"/>
                            </g>
                            
                            <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                                <path d="M336.54 326.11l43.53 -60.94c-18.27,10.17 -83.29,34.42 -83.35,34.54 0,0 -49.44,54.63 -61.39,64.97 -1.52,1.31 -1.18,0.81 1.21,0.14 3.32,-0.92 9.43,-3.4 15.89,-5.9 10.62,-4.11 21.98,-8.59 21.98,-8.59l0.56 -0.22 5.36 -2.06 1.4 -0.55 5.29 -2.04 1.01 -0.39 4.62 -1.8 1.4 -0.54 5.19 -2.02 1.53 -0.6 4.44 -1.73 1.2 -0.47 5.13 -2 1.8 -0.71 4.98 -1.95 0.11 -0.04 1.44 -0.57 4.03 -1.58 1.98 -0.78 10.66 -4.17z"/>
                                <path d="M632.21 205.07l-43.71 -18.08c15.25,-3.83 62.56,-26.66 62.64,-26.6 0,0 50.6,10.43 61.75,10.68 0.7,0.02 -30.92,13.14 -30.92,13.14l-0.44 0.18 -4.18 1.81 -1.09 0.47 -4.13 1.78 -0.79 0.34 -3.61 1.55 -1.09 0.47 -4.06 1.73 -1.19 0.51 -3.47 1.48 -0.94 0.41 -4.01 1.71 -1.41 0.6 -3.9 1.65 -0.08 0.04 -1.13 0.48 -3.15 1.33 -1.55 0.66 -9.54 3.66z"/>
                            </g>

                            <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                                <path d="M643.25 143c0,0 -28.06,-4.42 -51.59,-18.97 0,0 -37.97,11.63 -75.42,-2.69 0,0 23.34,31.06 56.81,40.97 3.44,1.02 30.51,-23.84 70.2,-19.31z"/>
                                <path d="M287.45 282.6c0,0 22.97,-26.62 34.28,-58.14 0,0 43.95,-21.05 68.42,-63.73 0,0 0.93,45.4 -23.05,81.08 -2.46,3.67 -45.92,4.6 -79.65,40.79z"/>
                            </g>

                            <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                                <path d="M17.11 315.8c0.17,0.21 3.62,-10.08 37.72,-54.79 0,0 1.58,-1.05 2.94,-1.82 2.77,-1.56 10.53,-6.96 34.69,-3.69 3.83,0.52 7.9,1.24 12.24,2.28 7.92,1.9 13.19,2.28 26.48,6.79 0,0 -0.84,22.86 -1.48,23.07 -7.69,2.46 -51.99,-29.49 -112.59,28.16z"/>
                                <path d="M723.47 64.3c0.29,-0.3 -1.16,-1.64 -3.68,-3.93 -2.98,-2.71 -6.6,-5.96 -6.72,-5.85 -4.4,3.74 -8.68,13.38 -9.8,15.45 0,0 9.8,10.62 9.88,10.41 1.11,-3.09 7.05,-12.69 10.32,-16.08z"/>
                                <path d="M192.67 292.29c0,0 -0.84,-22.77 -0.59,-21.85 0.04,-0.06 -0.09,-0.03 -0.02,-0.01 0.01,0 0.01,0 0.02,0.01 8.03,1.23 23.96,1.12 41.41,-2.16 17.08,-3.22 36.67,-14.68 50.89,-48.71 0,0 9.6,31.86 -29.32,64.2 0,0 -18.37,16.81 -62.39,8.52z"/>
                                <path d="M690.86 102.9c0,0 -10.8,-11.91 -10.54,-11.38 -1.39,-0.43 -14.08,10.44 -35.03,15.57 -13.4,3.28 -32.47,3.16 -58.53,-9.76 0,0 7.22,15.88 41.66,22.28 2.89,0.53 13.63,0.89 20.94,-0.29 10.29,-1.66 25.84,-6.59 41.5,-16.42z"/>
                            </g>

                            <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor4}>
                                <path d="M154.56 163.27c0.21,-0.84 -10.43,8.19 -17.38,13.95 -3.03,2.51 -14.57,12.4 -14.47,12.63 4.66,10.71 27.7,57.24 26.08,97.57 -1.03,25.8 -12.01,47.91 -14.51,54.6 -10.8,28.87 -30.11,37.19 -53.86,47.4 -20.57,8.83 -41.34,24.55 -55.65,7.23 -2.07,-2.51 -18.27,-50.04 -21.33,-55.54 0,0 -1.6,2.35 -2.5,7 -0.27,1.38 -0.32,2.92 -0.4,4.73 -0.03,0.66 -0.19,3.07 -0.04,3.62 4.32,16.29 13.29,41.2 22.79,49.72 8.91,8.01 19.44,10.8 30.69,11.8 16.63,1.48 33.98,-2.14 49.36,-5.94 10.61,-2.62 21.01,-6.81 28.58,-9.04 8.4,-1.53 86.05,-123.74 22.64,-239.73z"/>
                                <path d="M651.94 11.47c-0.02,-0.08 4.74,1.69 9.65,3.87 5.41,2.39 11.01,5.43 11.21,6.02 7.96,23.94 19.32,43.94 34.78,61.11 29.04,32.25 64.99,52.11 66.88,52.32 0.37,0.04 -0.62,4.31 -0.83,5 -0.61,1.96 -2.26,5.2 -3.13,5.85 -0.49,0.37 -1.21,0.96 -2.21,1.52 -2.88,1.59 -7.16,3.47 -7.73,3.45 -2.03,0.37 -97.7,-64.44 -108.62,-139.14z"/>
                            </g>

                            <g fill={logot}>
                                <path d="M485.36 152.75c6.33,-6.35 13.45,-10.77 15.55,-3.94 2.24,7.33 -5.8,22.55 -23.48,29.21 -3.65,1.38 -8.29,2.54 -11.47,2.79 -0.13,0.01 -0.16,0 -0.16,-0.02 0,-0.02 0.05,-0.05 0.15,-0.07 3.61,-1.12 6.99,-2.33 10.4,-4.09 11.03,-5.7 18.14,-14.52 17.01,-17.78 -0.85,-2.48 -5.87,-0.83 -11.62,3.66 -3.45,2.69 -6.8,6.2 -10.53,10.01 -9.11,9.3 -21.97,15.66 -31.97,8.11 -4.86,-3.67 -3.93,-7.82 1.57,-7.85 4.19,-0.02 7.98,2.78 11.45,4.88 2.06,1.25 3.89,2.12 6.4,2.52 0.08,0.01 0.12,0.03 0.12,0.05 -0.01,0.03 -0.08,0.04 -0.22,0.04 -3.22,0.13 -6.06,-1.01 -8.28,-1.92 -1.11,-0.45 -2.24,-0.65 -3.48,-0.62 -3.53,0.08 -4.48,1.89 -1.66,3.42 1.91,1.04 5.02,1.83 9.17,1.08 0.36,-0.07 0.73,-0.14 1.11,-0.23l-0.04 -0.01c0,0 0.07,-0.01 0.2,-0.03 13.05,-3.2 19.94,-19.34 29.78,-29.21z"/>
                            </g>

                            <g stroke="#000000" strokeWidth="0.3" fill="transparent">
                                <path d="M42.45 415.98c-4.45,-3.33 -11.82,-8.33 -14.84,-12.8 -0.25,-0.36 -0.48,-0.73 -0.69,-1.1 -1.6,-2.75 -2.59,-5.94 -3.64,-9.07 -4.21,-12.59 -9.12,-24.42 -13.7,-36.59 -2.72,-7.23 -3.01,-7.67 -5.93,-15.55"/>
                                <path d="M84.03 416.38c-5.2,-5.02 -15.02,-14.01 -19.3,-19.73 -6.42,-8.58 -10,-19.13 -13.81,-29.43 -6.34,-17.13 -13.33,-33.56 -19.22,-50.53 -2.56,-7.35 -2.92,-9.07 -5.25,-16.53"/>
                                <path d="M469.66 272.48c-0.16,-7.3 -0.52,-18.82 -1.87,-25.82 -3.12,-16.07 -12,-30.75 -21.05,-44.92 -12.9,-20.19 -26.13,-39.36 -39.56,-58.54 -21.38,-30.53 -43.25,-61.1 -65.15,-91.68"/>
                                <path d="M442.62 283.27c-0.15,-7.24 -0.5,-18.68 -1.85,-25.63 -3.09,-15.95 -11.91,-30.52 -20.89,-44.58 -12.81,-20.05 -25.95,-39.07 -39.29,-58.1 -21.23,-30.31 -42.94,-60.65 -64.68,-90.99"/>
                                <path d="M519.39 252.05c0.46,-4.97 1.1,-14.18 0.73,-19.05 -0.99,-12.76 -6.8,-24.93 -13.16,-36.5 -13.29,-24.21 -28.97,-45.86 -44.76,-67.58 -24.02,-33.04 -46.14,-63.42 -70.46,-96.67"/>
                                <path d="M493.58 262.87c0.46,-5 1.11,-14.27 0.73,-19.18 -0.99,-12.84 -6.84,-25.09 -13.24,-36.74 -13.38,-24.37 -29.15,-46.15 -45.05,-68.02 -24.17,-33.26 -46.43,-63.84 -70.91,-97.31"/>
                                <path d="M544.49 241.78c0.01,-8.63 -0.09,-21.19 -1.76,-29.41 -3.14,-15.38 -11.74,-29.44 -20.56,-43.06 -20.25,-31.28 -41.67,-60.24 -63.81,-89.04 -15.34,-19.97 -27.51,-35.4 -43.27,-55.27"/>
                                <path d="M568.18 232.16c0.04,-8.52 -0.01,-20.92 -1.65,-29.02 -3.08,-15.18 -11.61,-29.03 -20.37,-42.44 -20.1,-30.81 -41.38,-59.31 -63.38,-87.66 -15.25,-19.65 -27.33,-34.84 -43.01,-54.39"/>
                                <path d="M56.35 418.1c-5.11,-4.01 -13.95,-11.23 -17.72,-16.28 -4.44,-5.95 -7.17,-13.22 -9.74,-20.44 -4.75,-13.37 -8.95,-26.56 -13.3,-39.82 -2.19,-6.69 -2.57,-8.04 -4.77,-14.76"/>
                                <path d="M71.62 417.92c-4.96,-3.76 -14.65,-10.42 -17.97,-14.14 -3.2,-3.57 -5.4,-8.03 -7.49,-12.49 -5.2,-11.11 -9.68,-22.25 -13.71,-33.6 -5.71,-16.1 -9.01,-27.79 -14.12,-44.19"/>
                                <path d="M103.45 412.21c-6.9,-6.15 -17.8,-15.89 -23.23,-23.11 -8.36,-11.1 -13.23,-24.73 -18.01,-38.16 -6.56,-18.44 -12.94,-36.48 -19.27,-54.69 -1.87,-5.4 -2.76,-7.56 -4.63,-12.98"/>
                                <path d="M121.95 406.42c-7.52,-7.04 -18.15,-17.06 -24.06,-25.27 -6.59,-9.16 -11.21,-19.75 -15.63,-30.36 -11.9,-28.53 -21.6,-55.16 -31.92,-83.86"/>
                                <path d="M143.94 398.71c-5.97,-5.08 -14.73,-12.6 -19.65,-18.16 -8.45,-9.56 -14.53,-21.17 -20.02,-32.9 -7.45,-15.88 -13.84,-31.96 -20.17,-48.13 -6.66,-17.04 -12.85,-33.12 -19.43,-50.26"/>
                                <path d="M166.26 390.84c-6.11,-5.18 -15.47,-13.51 -20.48,-19.63 -4.58,-5.6 -8.29,-11.94 -11.71,-18.4 -7.84,-14.76 -14.23,-30.09 -20.82,-45.35 -10.86,-25.14 -22.19,-50.19 -33.63,-75.12"/>
                                <path d="M188.94 382.57c-6.3,-5.63 -16.25,-14.7 -21.24,-21.31 -6.09,-8.09 -10.28,-17.59 -14.71,-26.9 -7.89,-16.57 -16.52,-32.51 -24.54,-48.79 -11.21,-22.73 -21.85,-47.58 -31.72,-71.08"/>
                                <path d="M211.66 374.21c-3.98,-4.69 -10.92,-13.26 -14.63,-18.16 -8.89,-11.71 -16.39,-24.47 -23.38,-37.46 -12.89,-23.92 -24.06,-48.63 -35.17,-73.37 -6.96,-15.49 -15.25,-33.99 -22.18,-49.5"/>
                                <path d="M236.76 364.7c-5.85,-8.46 -14.47,-20.66 -19.92,-29.37 -12.18,-19.48 -22.44,-40.13 -32.99,-60.59 -11.23,-21.77 -22.78,-43.32 -34.18,-64.97 -4.52,-8.58 -11.1,-21.21 -15.59,-29.82"/>
                                <path d="M261.31 355.37c-5.55,-8.81 -13.65,-21.19 -18.95,-30.14 -12.11,-20.42 -23.03,-41.54 -34.09,-62.57 -14.91,-28.34 -30.09,-56.52 -45.41,-84.63 -1.88,-3.44 -6.3,-11.6 -8.18,-15.04"/>
                                <path d="M287.59 345.24c-3.57,-6.78 -9.13,-17.09 -12.76,-23.83 -14.67,-27.25 -30.25,-54 -45.65,-80.85 -17.02,-29.68 -36.37,-64.04 -53.16,-93.86"/>
                                <path d="M312.94 335.37c-3.43,-7.92 -8.42,-19.43 -12.12,-27.21 -11.04,-23.17 -24.42,-45.16 -37.6,-67.24 -21.15,-35.43 -43.86,-74.65 -64.46,-110.31"/>
                                <path d="M338.32 325.39c-2.26,-7.23 -5.72,-18.19 -8.46,-25.2 -8.65,-22.12 -21.84,-42.23 -34.61,-62.49 -25.03,-39.69 -49.72,-82.2 -73.02,-122.48"/>
                                <path d="M364.66 314.94c-3.08,-10.71 -7.28,-25.24 -11.54,-35.43 -7.29,-17.4 -18,-33.28 -28.14,-49.38 -13.85,-22.01 -26.64,-44.43 -39.91,-66.62 -12.44,-20.8 -25.94,-42.34 -38.84,-62.91"/>
                                <path d="M391.16 304.35c-1.11,-6.11 -2.92,-16.29 -4.61,-22.22 -3.33,-11.67 -7.99,-21.84 -13.94,-32.52 -8.43,-15.12 -18.55,-30.44 -27.7,-45 -22.14,-35.23 -43.96,-70.81 -66.72,-105.72 -2.55,-3.91 -5.19,-8.09 -7.76,-11.99"/>
                                <path d="M418.29 293.43c-0.61,-5.42 -2.15,-17.27 -3.01,-22.61 -2.19,-13.51 -10.23,-25.25 -18.68,-39.28 -17.77,-29.49 -36.09,-57.77 -54.99,-85.9 -15.84,-23.59 -31.92,-47.38 -47.94,-71.01"/>
                                <path d="M636.32 6.8c10.17,9.61 19.02,18.01 28.83,28 10.76,10.96 21.1,22.36 31.37,33.7 11.27,12.45 22.46,24.83 32.83,38.41 5.66,7.41 11.07,15.17 13.61,23.99 1.27,4.4 1.82,9.07 1.8,13.69 -0.03,4.13 -0.71,9.35 -1.32,13.43"/>
                                <path d="M649.77 10.71c12.29,11.52 23.16,21.67 35.31,33.41 16.43,15.9 32.61,32.17 46.61,50.16 7.55,9.7 14.46,19.9 19.48,31.36 2.01,4.57 3.72,9.35 4.04,14.18 0.28,4.02 -0.8,9.82 -1.72,13.89"/>
                                <path d="M661.39 15.27c11.52,10.78 21.78,20.33 33.11,31.03 17.38,16.42 34.33,32.67 49.99,52.27 9.17,11.48 17.9,24.11 18.65,38.09 0.18,3.49 -0.43,9.72 -0.82,13.26"/>
                                <path d="M675.2 22.66c8.62,8.08 16.76,15.78 25.26,23.8 17.38,16.42 34.33,32.67 49.99,52.27 9.17,11.48 17.9,24.11 18.65,38.09 0.05,0.93 -0.04,9 -0.06,9.94"/>
                                <path d="M651.58 197.02c1.24,-4.29 2.8,-9.88 3.11,-14.23 0.54,-7.45 -1.4,-15.06 -3.99,-22.26 -3.61,-10.05 -8.49,-19.31 -13.98,-28.22 -10.01,-16.24 -22.04,-31.28 -34.06,-46.24 -14.86,-18.51 -29.7,-36.91 -45.11,-54.84 -9.33,-10.86 -16.28,-18.66 -25.87,-29.32"/>
                                <path d="M670 189.23c1.39,-5.36 2.78,-11.43 3.06,-16.91 0.34,-6.64 -0.82,-13.45 -3.08,-19.72 -3.4,-9.46 -9.31,-17.71 -15.23,-25.88 -15.91,-21.97 -31.91,-43.42 -48.91,-64.16 -17.94,-21.88 -34.95,-40.73 -54.12,-61.72"/>
                                <path d="M685.91 182.51c1.67,-5.69 3.44,-11.82 3.71,-17.58 0.43,-9.41 -2.8,-19.01 -7.14,-27.66 -3.96,-7.89 -8.86,-15 -13.91,-22.02 -12.09,-16.8 -25.1,-33.1 -38.43,-49.08 -19.06,-22.86 -37.57,-43.58 -57.36,-65.73"/>
                                <path d="M702.08 175.95c1.55,-5.41 3.19,-11.25 3.39,-16.74 0.32,-8.99 -2.88,-18.19 -7.15,-26.5 -3.89,-7.58 -8.66,-14.42 -13.59,-21.17 -11.8,-16.18 -24.47,-31.88 -37.45,-47.28 -18.56,-22.03 -36.57,-42.01 -55.82,-63.37"/>
                                <path d="M716.46 169.53c1.08,-4.09 2.21,-8.55 2.76,-12.75 0.64,-4.79 0.64,-9.7 -0.28,-14.41 -1.03,-5.3 -3.24,-10.33 -5.74,-15.17 -4.73,-9.17 -10.48,-17.66 -16.69,-25.78 -8.89,-11.63 -18.74,-22.51 -28.3,-33.63 -9.53,-11.1 -18.78,-22.43 -28.52,-33.32 -10.25,-11.46 -20.06,-21.42 -30.97,-32.29"/>
                                <path d="M730.43 163.59c0.92,-3.81 1.89,-7.96 2.32,-11.89 0.49,-4.46 0.38,-9.07 -0.6,-13.5 -1.11,-4.99 -3.34,-9.75 -5.83,-14.35 -4.71,-8.7 -10.38,-16.79 -16.49,-24.55 -8.74,-11.1 -18.37,-21.52 -27.72,-32.17 -9.34,-10.62 -18.4,-21.46 -27.93,-31.89 -10.03,-10.98 -19.6,-20.54 -30.24,-30.98"/>
                                <path d="M632.18 205.19c1.19,-5.66 2.75,-13.18 2.62,-18.82 -0.14,-6.55 -1.96,-13.08 -4.3,-19.33 -4.83,-12.89 -11.88,-24.59 -19.49,-35.87 -14.49,-21.48 -31.03,-41.49 -47.46,-61.53 -18.9,-23.06 -34.61,-42.41 -53.38,-65.52"/>
                                <path d="M591.49 222.45c0.08,-8.4 0.08,-20.63 -1.52,-28.61 -3.01,-14.96 -11.47,-28.57 -20.15,-41.76 -19.95,-30.28 -41.08,-58.3 -62.92,-86.15 -15.15,-19.31 -27.15,-34.23 -42.72,-53.44"/>
                                <path d="M615.12 212c0.13,-8.22 0.22,-20.18 -1.33,-27.98 -2.9,-14.6 -11.25,-27.86 -19.82,-40.69 -19.71,-29.49 -40.6,-56.75 -62.21,-83.84 -14.98,-18.78 -26.86,-33.29 -42.26,-51.98"/>
                            </g>

                        </svg>

                    </div>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <div className="svg-container2 mb-3">

                        <svg viewBox="0 0 801 163.87" ref={svg_bottom}>
                            <g stroke="#000000" strokeWidth="0.5" fill={corb}>
                                <path class="fil0 str0" d="M400.42 0.67c0,0 339.89,-6.66 399.61,91.17 0,0 2.18,26.72 -3.1,36.28 0,0 -8.49,1.27 -11.97,1.92 -3.47,0.65 -154.79,36.22 -384.47,33.18 -229.68,3.25 -381.02,-32.17 -384.51,-32.81 -3.48,-0.65 -11.95,-1.91 -11.95,-1.91 -5.31,-9.56 -3.13,-36.28 -3.13,-36.28 59.37,-99.69 399.49,-91.75 399.52,-91.55l0 0z"/>
                            </g>

                            <g stroke="#A6A6A6" strokeWidth="1" fill={cor3}>
                                <path class="fil1 str1" d="M224.17 78.61c0,0 -21.39,-13.91 -59.76,-9.85 -14.22,1.5 -29.17,8.5 -47.22,17.01 0.29,-0.41 -12.78,-11.07 -12.52,-11.32 0,0 19.73,-14.92 47.24,-17.01 27.54,-2.1 62.86,8.63 72.26,21.17l0 0z"/>
                                <path class="fil1 str1" d="M756.05 133.68c1.55,0.44 2.36,1.02 3.28,1.33 0.62,0.2 37.9,-6.88 37.94,-6.98 0.14,-0.39 0.29,-0.86 0.46,-1.41 0.21,-0.63 1.05,-3.02 1.4,-4.25 0.37,-1.31 1.49,-8.33 1.39,-11.32 -0.06,-1.91 0.01,-6.64 0.01,-8.5 0,-0.85 0.01,-1.82 0.02,-2.82 0.01,-1.41 0.01,-2.9 -0.02,-4.26 0,-0.18 -0.17,-3.18 -0.26,-3.35 -0.42,-0.84 0.16,0.23 0,0 -0.34,-0.53 -0.75,0.66 -1.18,0.52 -0.63,-0.2 -1.42,-0.99 -1.43,-0.68 -0.16,9.25 -0.45,33.3 -0.83,33.32 -2.78,0.11 -11.38,-0.04 -25.49,-2.93 -13.89,-2.83 -29.75,-10.75 -45.84,-22.67 -1.76,1.25 -12.5,7.08 -13.89,8.49 -0.26,0.26 17.9,17.88 44.44,25.51z"/>
                                <path class="fil1 str1" d="M44.96 133.95c-1.56,0.44 -2.37,1.03 -3.29,1.33 -0.62,0.2 -37.9,-6.88 -37.94,-6.98 -0.14,-0.39 -0.28,-0.87 -0.46,-1.41 -0.21,-0.64 -1.04,-3.01 -1.39,-4.25 -0.37,-1.32 -1.49,-8.34 -1.4,-11.33 0.06,-1.9 0,-6.64 0,-8.49 -0.01,-0.85 -0.02,-1.82 -0.02,-2.82 -0.02,-1.41 -0.02,-2.9 0.01,-4.26 0.01,-0.18 0.17,-3.18 0.26,-3.35 0.42,-0.85 -0.16,0.24 0,0 0.34,-0.52 0.77,0.66 1.19,0.52 0.62,-0.21 1.42,-0.99 1.43,-0.69 0.15,9.25 0.44,33.3 0.83,33.32 2.77,0.12 11.37,-0.03 25.48,-2.91 13.9,-2.84 29.75,-10.76 45.84,-22.68 1.76,1.26 12.51,7.07 13.91,8.49 0.24,0.26 -17.9,17.89 -44.45,25.51z"/>
                                <path class="fil1 str1" d="M576.85 78.35c0,0 21.37,-13.93 59.74,-9.86 14.22,1.5 29.18,8.51 47.24,17.01 -0.3,-0.4 12.77,-11.07 12.5,-11.32 0,0 -19.73,-14.92 -47.22,-17.01 -27.55,-2.11 -62.88,8.62 -72.26,21.18z"/>
                            </g>

                            <g stroke="#A6A6A6" strokeWidth="1" fill={cor4}>
                                <path class="fil3 str1" d="M134.21 149.47c-1.08,-0.15 -5.85,-13.93 -31.38,-42.11 -19.55,-21.59 -74.93,-28.12 -100.97,-14.45 -0.06,-0.16 -0.4,-0.29 -0.79,-0.62 -0.24,-0.23 -0.19,-0.1 -0.14,-0.24 0.52,-1.54 11.7,-15.21 15.03,-18.16 11.69,-10.33 22.01,-16.4 33.69,-21.87 0.85,-0.4 1.66,-0.31 2.21,-0.2 0,0 15.37,-0.7 45.8,22.56 27.86,21.3 52.24,57.58 64.01,78.21 0.15,0.25 -1.67,-0.08 -4.61,-0.43 -6.63,-0.77 -18.43,-2.08 -22.85,-2.69l0 0z"/>
                                <path class="fil3 str1" d="M666.8 149.19c1.07,-0.15 5.85,-13.91 31.37,-42.1 19.57,-21.6 74.94,-28.11 100.97,-14.45 0.06,-0.15 0.41,-0.29 0.79,-0.62 0.25,-0.23 0.21,-0.09 0.16,-0.24 -0.53,-1.54 -11.71,-15.21 -15.05,-18.17 -11.67,-10.33 -22,-16.39 -33.69,-21.85 -0.85,-0.41 -1.66,-0.32 -2.21,-0.21 0,0 -15.37,-0.71 -45.8,22.56 -27.86,21.3 -52.23,57.58 -63.99,78.2 -0.15,0.27 1.66,-0.07 4.6,-0.41 6.62,-0.78 18.42,-2.1 22.85,-2.71l0 0z"/>
                                <path class="fil3 str1" d="M796.98 128.1c0.19,-0.04 0.29,-0.06 0.29,-0.07 0.14,-0.39 0.29,-0.86 0.46,-1.41 0.21,-0.63 1.05,-3.02 1.4,-4.25 0.37,-1.31 1.49,-8.33 1.39,-11.32 -0.06,-1.91 0.01,-6.64 0.01,-8.5 0,-0.85 0.01,-1.82 0.02,-2.82 0.01,-1.41 0.01,-2.9 -0.02,-4.26 0,-0.18 -0.17,-3.18 -0.26,-3.35 -0.42,-0.84 0.16,0.23 0,0 -0.34,-0.53 -0.75,0.66 -1.18,0.52 -0.63,-0.2 -1.42,-0.99 -1.43,-0.68 -0.14,7.97 -0.37,26.92 -0.68,32.03l0 4.11z"/>
                                <path class="fil3 str1" d="M4.02 128.1c-0.18,-0.04 -0.28,-0.06 -0.29,-0.07 -0.13,-0.39 -0.28,-0.86 -0.46,-1.41 -0.21,-0.63 -1.04,-3.02 -1.4,-4.25 -0.36,-1.31 -1.48,-8.33 -1.38,-11.32 0.05,-1.91 -0.01,-6.64 -0.01,-8.5 0,-0.85 -0.01,-1.82 -0.02,-2.82 -0.01,-1.41 -0.01,-2.9 0.02,-4.26 0,-0.18 0.16,-3.18 0.25,-3.35 0.42,-0.84 -0.16,0.23 0,0 0.35,-0.53 0.76,0.66 1.18,0.52 0.63,-0.2 1.43,-0.99 1.44,-0.68 0.13,7.97 0.37,26.92 0.67,32.03l0 4.11z"/>
                            </g>

                            <g fill={logob}>
                                <path class="fil2 str0" d="M373.9 52.86c-10.98,4.84 -22.29,6.86 -22.6,-2.81 -0.32,-10.36 16.27,-25.51 42.77,-25.08 5.47,0.1 12.2,0.95 16.63,2.23 0.17,0.05 0.22,0.08 0.21,0.1 0,0.03 -0.09,0.03 -0.24,0.02 -5.31,-0.39 -10.36,-0.56 -15.65,-0.05 -17.1,1.67 -30.05,9.22 -29.73,13.91 0.23,3.55 7.68,3.97 17.16,1.2 5.69,-1.67 11.54,-4.41 18.04,-7.35 15.83,-7.18 35.67,-8.75 46.45,5.75 5.24,7.06 2.43,11.83 -5.04,9.12 -5.71,-2.07 -9.82,-7.49 -13.75,-11.88 -2.34,-2.6 -4.5,-4.62 -7.76,-6.38 -0.11,-0.06 -0.15,-0.1 -0.14,-0.12 0.01,-0.03 0.12,-0.01 0.3,0.05 4.42,1.46 7.85,4.31 10.53,6.57 1.34,1.12 2.82,1.94 4.51,2.53 4.82,1.66 6.78,-0.15 3.52,-3.49 -2.2,-2.26 -6.14,-4.81 -12.05,-5.94 -0.51,-0.1 -1.06,-0.19 -1.61,-0.27l0.06 0.02c0,0 -0.1,-0.02 -0.28,-0.05 -18.93,-2.49 -34.3,14.4 -51.33,21.92l0 0z"/>
                            </g>
            
                        </svg>

                    </div>                    
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta09" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>
                        <div><div><b>Design 04: </b>{Cores[cor4].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor4} clique={ setCor4 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta09" selecionada={corb} clique={ setCorb }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Sensus;