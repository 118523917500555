import React from 'react';

function PaletaCores(props) {

    // eslint-disable-next-line no-unused-vars
    const Paleta01 = { 
        colors: [  
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#094A39", PANTONE: "C 328",   TISSUE: "GN W913" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" },
            // { RGB: "#3C7196", PANTONE: "C 7454",  TISSUE: "BL W280" },
            { RGB: "#535A62", PANTONE: "C 5497",  TISSUE: "GR 0122" },
            { RGB: "#191D24", PANTONE: "C BLACK", TISSUE: "BK W001" }
        ]
    }

    // eslint-disable-next-line no-unused-vars
    const Paleta02 = {
        colors: [
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" }
        ]
    }

    // eslint-disable-next-line no-unused-vars
    const Paleta03 = {
        colors: [ 
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#094A39", PANTONE: "C 328",   TISSUE: "GN W913" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" },
            // { RGB: "#3C7196", PANTONE: "C 7454",  TISSUE: "BL W280" },
            { RGB: "#535A62", PANTONE: "C 5497",  TISSUE: "GR 0122" },
            { RGB: "#191D24", PANTONE: "C BLACK", TISSUE: "BK W001" },
            { RGB: "#AC211A", PANTONE: "C 180",   TISSUE: "RE W022" }
        ]
    }   

    // eslint-disable-next-line no-unused-vars
    const Paleta04 = {
        colors: [
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" }
        ]
    }
  
    // eslint-disable-next-line no-unused-vars
    const Paleta05 = {
        colors: [ 
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#094A39", PANTONE: "C 328",   TISSUE: "GN W913" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" },
            // { RGB: "#3C7196", PANTONE: "C 7454",  TISSUE: "BL W280" },
            { RGB: "#535A62", PANTONE: "C 5497",  TISSUE: "GR 0122" },
            { RGB: "#191D24", PANTONE: "C BLACK", TISSUE: "BK W001" },
            { RGB: "#B5E61D", PANTONE: "C BLACK", TISSUE: "BK W001" },
            { RGB: "#48BCD9", PANTONE: "C BLACK", TISSUE: "BK W001" }
        ]
    }
  
    // eslint-disable-next-line no-unused-vars
    const Paleta06 = {
        colors: [
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" }
        ]
    }
  
    // eslint-disable-next-line no-unused-vars
    const Paleta07 = {
        colors: [
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" }
        ]
    }

    // eslint-disable-next-line no-unused-vars
    const Paleta08 = {
        colors: [
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" }
        ]
    }

    // eslint-disable-next-line no-unused-vars
    const Paleta09 = {
        colors: [
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F75439", PANTONE: "C 021",   TISSUE: "OR W470" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" },
            { RGB: "#191D24", PANTONE: "C BLACK", TISSUE: "BK W001" }
        ]
    }
      
    // eslint-disable-next-line no-unused-vars
    const Paleta10 = {
        colors: [
            { RGB: "#FFFFFF", PANTONE: "C WHITE", TISSUE: "WH W001" },
            { RGB: "#FBE111", PANTONE: "C 102",   TISSUE: "YE W029" },
            { RGB: "#F00008", PANTONE: "C 200",   TISSUE: "RE W001" },
            { RGB: "#1F4194", PANTONE: "C 2728",  TISSUE: "BL W320" },
            { RGB: "#191D24", PANTONE: "C BLACK",  TISSUE: "BK W001" }
        ]
    }

    // useEffect(() => {
    //     console.log('paleta',props.paleta);
    //     // const conf = JSON.parse(eval(props.paleta));
    //     const conf = eval(props.paleta);
    // },[])

    function cliqueCor(event,cor) {
        event.preventDefault();
        props.clique(cor);
    }

    return <>
        {
            // eslint-disable-next-line no-eval
            eval(props.paleta).colors.map((item,idx) => (
                <span className={props.selecionada===item.RGB?"me-1 paleta paleta-selecionada":"me-1 paleta"} style={{ backgroundColor: item.RGB}} onClick={(e) => cliqueCor(e,item.RGB)} key={idx}></span>
            ))
        }
    </>
}

export default PaletaCores;